import { createAction } from 'redux-actions';

export enum ProfileFields {
  // Users
  PHONE_NUMBER = 'phoneNumber',
  LOCATION = 'location',
  PHOTO = 'photo',
  RESUME = 'resume',
  ABOUT_ME = 'aboutMe',
  PORTFOLIO = 'portfolio',
  SOCIAL_MEDIA = 'socialMedia',
  WHATSAPP_NUMBER = 'whatsappNumber',
  CAREER_START_DATE = 'careerStartDate',

  // UserSkills
  SKILLS = 'skills',

  // Experiences
  WORK_EXPERIENCE = 'workExperience',
  EDUCATION = 'education',
  AWARD = 'award',
  CCA = 'cca',

  // Preferences
  JOB_FUNCTIONS = 'jobFunctions',
  JOB_TYPES = 'jobTypes',
  WORK_LOCATIONS = 'workLocations',
  SALARY_EXPECTATION = 'salaryExpectation',

  // Certificates
  CERTIFICATE = 'certificate',
}

const Actions = {
  EDIT_PROFILE: 'glints/GoogleTagManager/PROFILE_EDIT_FIELD',
};

export const trackEditProfileField = createAction(
  Actions.EDIT_PROFILE,
  (field: ProfileFields) => ({
    field,
    actionType: 'update',
  })
);

export const trackDeleteProfileField = createAction(
  Actions.EDIT_PROFILE,
  (field: ProfileFields) => ({
    field,
    actionType: 'delete',
  })
);
